<template>
    <div class="container-xl" v-if="!$store.getters.isForbidden">
      <div class="card col-lg-12">
        <div class="card-header justify-content-between">
          <div class="card-title">{{ $route.name }} List</div>
          <div class="flex flex-row float-right">
            <!-- <el-button v-if="currentRole.can_create" type="primary" @click="doShowModalCreate" size="small"><font-awesome-icon icon="plus"/> Create new Promo</el-button> -->
          </div>
        </div>
        <!-- <div class="card-body">
          <div class="flex">
            <div class="w-1/3">
              <el-form ref="form2" label-width="150px">
                <el-form-item label="Review Status">
                  <el-select size="small" v-model="review_status_filter" placeholder="Review status" class="mr-2">
                    <el-option label="All" value=""/>
                    <el-option label="Approved" value="approved"/>
                    <el-option label="In Review" value="in_review"/>
                  </el-select>
                </el-form-item>
                <el-form-item label="Promo Status">
                  <el-select size="small" v-model="status_filter" placeholder="Promo status" class="mr-2">
                    <el-option label="All" value=""/>
                    <el-option label="Active" value="1"/>
                    <el-option label="Inactive" value="0"/>
                    <el-option label="Disabled" value="2"/>
                  </el-select>
                </el-form-item>
              </el-form>
            </div>
            <div class="w-2/3">
              <el-form ref="form2" label-width="150px">
                <el-form-item label="Promo Effective Date">
                  <el-date-picker
                    v-model="search_date"
                    type="daterange"
                    size="small"
                    class="w-50"
                    range-separator="To"
                    start-placeholder="Start date"
                    end-placeholder="End date">
                  </el-date-picker>
                </el-form-item>
              </el-form>
            </div>
          </div>
          <el-button type="info" @click="searchHandler" size="small"><font-awesome-icon icon="search"/> Search</el-button>
        </div> -->
        <!-- {{ list }} -->
        <div class="table-responsive">
          <table class="table card-table text-nowrap table-vcenter">
            <thead>
              <tr>
                <th width="20%">Created at </th>
                <th width="20%">Company Name</th>
                <th width="20%">User Name</th>
                <th>Email</th>
                <th>Phone Number</th>
                <!-- <th>Company ID</th> -->
                <th>Status</th>
                <th>Detail</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody v-loading="isLoading">
              <tr v-for="(item, i) in list" :key="i">
                <td>{{ item.created_at_str }}</td>
                <td>{{ item.data?.company_name }}</td>
                <td>{{ item.user.full_name }}</td>
                <td>{{ item.user.email }}</td>
                <td>{{ item.user.phone_number }}</td>
                <!-- <td>{{ item.company_id }}</td> -->
                <td>
                  <span class="tag" :class="item.status_color">{{ item.status_str }}</span>
                </td>
                <td class="text-nowrap text-muted child1-sticky">
                    <button @click="viewDetailData(item)" title="View Detail" type="button"
                    class="btn btn-icon btn-flickr btn-sm"><font-awesome-icon icon="eye" /></button>
                </td>
                <td class="text-nowrap text-muted child1-sticky">
                    <button @click="viewDetail(item)" title="View Detail" type="button"
                    class="btn btn-icon btn-flickr btn-sm">Update</button>
                </td>
              </tr>
            </tbody>
          </table>
          <el-empty description="No data found" v-if="total_rows == 0" :image="emptyStateImage"></el-empty>
        </div>
        <div class="card-footer d-flex justify-content-between">
          <small>Showing {{ showingFrom }} to {{ showingUntil }} of {{ total_rows }} entries</small>
          <div class="inline-table">
            <b-pagination
              class="table-cell"
              @change="pageChangeHandler"
              :per-page="per_page"
              :limit="5"
              :total-rows="total_rows"
              v-model="page"
            />
          </div>
        </div>
        <b-modal size="lg" v-model="showDetailRequest" :title="'Detail #' + selected_item.id" hide-footer  @show="onModalShow">
          <b-form @submit.prevent="doUpdateStatus" @reset="closeModalRequest">
            <b-form-group label="Created at">
              <b-input
                v-model="selected_item.created_at"
                :disabled="true"
                type="text"/>
            </b-form-group>
            <b-form-group label="User Name">
              <b-input
                v-model="selected_item.user.full_name"
                :disabled="true"
                type="text"/>
            </b-form-group>
            <b-form-group label="Email">
              <b-input
                v-model="selected_item.user.email"
                :disabled="true"
                type="text"/>
            </b-form-group>
            <b-form-group label="Phone Number">
              <b-input
                v-model="selected_item.user.phone_number"
                :disabled="true"
                type="text"/>
            </b-form-group>
            <b-form-group label="Status">
              <b-input
                v-model="displayLastLogStatus"
                :disabled="true"
                type="text"/>
            </b-form-group>
            <b-form-group label="Set Status">
              <el-select
                class="w-full"
                v-model="selected_item.status"
              >
                <el-option
                  v-for="(item, index) in filteredStatusOptions"
                  :key="index"
                  :value="item.value"
                  :label="item.label"
                />
              </el-select>
            </b-form-group>
            <b-form-group label="Reason" v-if="['rejected_by_company', 'rejected_by_bd', 'cancelled'].includes(selected_item.status)">
              <b-textarea
                v-model="selected_item.reason"
                placeholder="Input reason"/>
            </b-form-group>
            <div class="d-flex float-right">
              <el-button :disabled="isSubmitDisabled" @click="doUpdateStatus"  class="mr-2 ml-2" size="small" type="primary">Submit</el-button>
              <el-button @click="closeModalRequest" size="small" class="mr-2">Cancel</el-button>
            </div>
          </b-form>
        </b-modal>
        <b-modal size="lg" v-model="showDetailRequestData" :title="'Detail #' + selected_item.id" hide-footer  @show="onModalShow">
          <b-form @reset="closeModalRequestData">
            <b-form-group label="PIC Name">
              <b-input
                v-model="selected_item.data.pic_name"
                :disabled="true"
                type="text"/>
            </b-form-group>
            <b-form-group label="PIC Email">
              <b-input
                v-model="selected_item.data.pic_email"
                :disabled="true"
                type="text"/>
            </b-form-group>
            <b-form-group label="PIC Phone Number">
              <b-input
                v-model="selected_item.data.pic_phone_number"
                :disabled="true"
                type="text"/>
            </b-form-group>
            <b-form-group label="Address">
              <b-input
                v-model="selected_item.data.address"
                :disabled="true"
                type="text"/>
            </b-form-group>
            <div class="d-flex float-right">
              <el-button @click="closeModalRequestData" size="small" class="mr-2">Cancel</el-button>
            </div>
          </b-form>
        </b-modal>
      </div>
    </div>
</template>
<script>
import { truncate } from 'lodash';
import moment from 'moment';
import useVuelidate from '@vuelidate/core';
import { quillEditor } from 'vue-quill-editor';
import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css';
import 'quill/dist/quill.bubble.css';
import emptyStateImage from '@/assets/images/empty-state.png';
import popupErrorMessages from '@/library/popup-error-messages';
import {
  GET_REQUEST_COMPANY, UPDATE_REQUEST_JOIN
} from '@/store/modules/companies';

export default {
  name: 'Submission',
  components: {
    quillEditor,
  },
  metaInfo: {
    title: 'Submission',
  },
  setup() {
    return {
      v$: useVuelidate(),
    };
  },
  data() {
    return {
      emptyStateImage,
      truncate,
      moment,
      loading: {
        create_promo: false,
      },
      showModalCreate: false,
      selected_item: {
       id: '',
       created_at: '',
       user: {
            full_name: '',
            email: '',
            phone_number: ''
        },
        status: '',
        data : {
          pic_name: '',
          pic_email: '',
          pic_phone_number: '',
          company_name: '',
          address: ''
        },
        reason: '' 
      },
      showDetailRequest: false,
      showDetailRequestData: false,
      per_page: 20,
      per_per_page: 10,
      total_rows: 0,
      page: 1,
      list: [],
      isLoading: true,
      search_keyword: '',
      search_by: '',
      search_date: [],
      form: {
        request_id: '',
        status: '',
        user_id: null,
        reason: ''
      },
      user_id: null,
      datePickerOptions: {
        disabledDate: (date) => moment(date).isBefore(moment(), 'days'),
      },
      status_filter: '',
      status_option: [
        {
          value: 'submitted',
          label: 'Submitted',
        },
        {
          value: 'waiting_verification',
          label: 'Waiting Verification',
        },
      ],
      status_options: [
        {
          value: 'waiting_verification',
          label: 'Waiting Verification',
        },
        {
          value: 'approved',
          label: 'Approved',
        },
        {
          value: 'rejected_by_company',
          label: 'Rejected by Company',
        },
        {
          value: 'rejected_by_bd',
          label: 'Rejected by BD',
        },
        {
          value: 'rejected_by_hr',
          label: 'Rejected by HR',
        },
        {
          value: 'cancelled',
          label: 'Cancelled',
        }
      ]
    };
  },
  computed: {
    showingFrom() {
      return this.total_rows ? ((this.page - 1) * this.per_page) + 1 : 0;
    },
    showingUntil() {
      if (this.total_rows) {
        if (this.list.length < this.per_page) {
          return this.total_rows;
        }
        return this.page * this.per_page;
      }
      return 0;
    },
    isSubmitDisabled() {
      const statusRequiresReason = ['rejected_by_company', 'rejected_by_bd', 'cancelled'].includes(this.selected_item.status);
      return statusRequiresReason && !this.selected_item.reason;
    },
    displayLastLogStatus() {
      const statusMap = {
        submitted: 'Submitted',
        waiting_verification: 'Waiting Verification',
        approved: 'Approved',
        rejected_by_hr: 'Rejected By HR',
        rejected_by_bd: 'Rejected By BD',
        cancelled: 'Cancelled'
      };
      return statusMap[this.lastLogStatus] || '';
    },
    lastLogStatus() {
      if (this.selected_item?.company_request_logs?.length) {
        return this.selected_item.company_request_logs
          .slice()
          .sort((a, b) => new Date(b.created_at) - new Date(a.created_at))[0]
          .status;
      }
      return null;
    },
    filteredStatusOptions() {
      if (this.lastLogStatus === 'submitted') {
        return this.status_options.filter(
          (option) => option.value === 'waiting_verification'
        );
      } else if (this.lastLogStatus === 'waiting_verification') {
        return this.status_options.filter((option) =>
          ['approved', 'rejected_by_company', 'rejected_by_bd', 'cancelled'].includes(option.value)
        );
      }
      return [];
    },
  },
  async mounted() {
    const loader = this.$loading.show();
    await this.getRequestList();
    loader.hide();
  },
  methods: {
    pageChangeHandler(page) {
      this.page = page;
      this.getRequestList();
    },
    searchHandler() {
      this.page = 1;
      this.getRequestList();
    },
    isFinalStatus(status) {
      return ['approved', 'rejected_by_company', 'rejected_by_hr', 'rejected_by_bd', 'cancelled', 'accepted', 'test'].includes(status);
    },
    async getRequestList() {
      this.loading.data = true;
      await this.$store.dispatch(GET_REQUEST_COMPANY, {
        page: this.page,
        per_page: 10,
        type: "create"
      });
      this.loading.data = false;
      const { rows, count } = this.$store.getters.request_join;
      this.list = rows;
      this.total_rows = count; 
      this.isLoading = false;
    },
    viewDetail(item) {
      this.showDetailRequest = true;
      this.selected_item = item;
    },
    viewDetailData(item) {
      this.showDetailRequestData = true;
      this.selected_item = item;
    },
    doShowModalCreate() {
      this.$router.push({
        name: 'Create Promo',
        params: {
          action: 'add',
        },
      });
    },
    onModalShow() {
      this.originalStatus = this.selected_item.status; 
    },
    closeModalRequest() {
      this.selected_item.status = this.originalStatus; 
      this.showDetailRequest = false;
    },
    closeModalRequestData() {
      this.selected_item.status = this.originalStatus; 
      this.showDetailRequestData = false;
    },
    async doUpdateStatus() {
      this.$confirm('You will update the status of this company request. Make sure you have input the data correctly. Continue?', 'Confirmation', {
        type: 'info',
        beforeClose: async (action, instance, done) => {
          if (action === 'confirm') {
            done();
            const requestBody = {
              request_id: this.selected_item.id,
              status: this.selected_item.status,
              user_id: this.user_id,
              reason: this.selected_item.reason
            };
            await this.$store.dispatch(UPDATE_REQUEST_JOIN, { data: requestBody })
              .then(() => {
                this.$message({
                  title: 'Success',
                  type: 'success',
                  message: 'Status updated',
                });
                this.showDetailRequest = false;
                this.getRequestList();
              }).catch((err) => {
                popupErrorMessages(err.response.data);
              });
              this.showDetailRequest = false;
            return;
          }
          done();
        },
      }).catch(() => {});
    },
  },
  async created() {
    this.user_id = localStorage.getItem('user_id');
  },
};
</script>
<style>
.el-tooltip__popper {
  max-width: 250px;
}
</style>
